<template>
  <div v-if="currentAccountSets" class="flex items-center">
    <Select
      @change="changeAccountSets"
      :datas="myAccountSets"
      v-model="currentAccountSetsId"
      autosize
      :deletable="false"
      class="mr-20px"
      filterable
      className="as-select"
      keyName="id" titleName="companyName">
      <template #item="{item}">
        {{ item.companyName }}
        <div class="text-gray-400 text-12px mt-5px">
          ({{ dictMapping(item.accountStandard, 'accountingStandards') }}
          -
          {{ dictMapping(item.vatType, 'vatRadios') }})
        </div>
      </template>
    </Select>
    <template v-if="SaCheckPermission('setting-accountset-canview')">
      <Tooltip content="账套管理">
        <div @click="editAs" class="fi fi-setting text-hover mr-34px !w-22px !h-22px"></div>
      </Tooltip>
    </template>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import {dictMapping, loading, message} from "heyui.ext";
import common from "@api/common";

export default {
  name: 'AppHeaderAccountSets',
  data() {
    return {
      messageList: [],
      currentAccountSetsId: null
    };
  },
  methods: {
    dictMapping,
    ...mapMutations(['newTab']),
    changeAccountSets(as) {
      if (as.id !== this.currentAccountSets.id) {
        loading("正在切换，请稍后...");
        common.changeAccountSets(as.id).then(() => {
          message('切换成功！');
          localStorage.removeItem(this.currentTabKey);
          window.location.replace("/")
        });
      }
    },
    editAs() {
      this.newTab({
        key: 'Account',
        title: "账套"
      })
    }
  },
  computed: {
    ...mapState(['currentAccountSets', 'myAccountSets']),
    ...mapGetters(['currentTabKey']),
  },
  watch: {
    'currentAccountSets.id'(id) {
      this.currentAccountSetsId = id;
    }
  },
  created() {
    if (this.currentAccountSets) {
      this.currentAccountSetsId = this.currentAccountSets.id;
    }
  }
};
</script>
<style lang='less'>
.app-header-message-vue {
  float: left;
  margin-right: 10px;

  .h-dropdowncustom-show {
    height: @layout-header-height;

    .app-header-icon-item {
      margin-right: 0;
    }

    &.h-pop-trigger {
      background: @hover-background-color;
    }
  }
}

.app-account-sets-dropdown-dropdown-container {
  width: 300px;
  min-height: 300px;

  .common-list-container {
    .common-list-item {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background: @hover-background-color;
      }

      &.current {
        background: @primary-color;
        color: @white-color;
      }
    }
  }
}
</style>
